import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
  | { pageId: "login.ftl" }
  | { pageId: "login-otp.ftl" }
  | { pageId: "login-config-totp.ftl" }
  | { pageId: "login-verify-email.ftl" }
  | { pageId: "my-extra-page-1.ftl" }
  | { pageId: "my-extra-page-2.ftl"; someCustomValue: string }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { pageId: "register.ftl"; authorizedMailDomains: string[] };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context
// (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default,
// see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: "login-otp.ftl",
      // @ts-expect-error: error
      login: {
        username: "test@test.io",
      },

      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
    },
    {
      pageId: "login-config-totp.ftl",
      // @ts-expect-error: error
      login: {
        username: "test@test.io",
      },
      totp: {
        totpSecretEncoded: "NZGG ISSU NJ2V MTKK GR3H C6RW OMZX CZLU",
        totpSecretQrCode:
          // eslint-disable-next-line max-len
          "iVBORw0KGgoAAAANSUhEUgAAAPYAAAD2AQAAAADNaUdlAAACdklEQVR4Xu2XsW4EIQxE6fhlOrvjl11EIvNMpOxulHRYKZbc5W79KEYe23Bt/bk+2jNyXy9/Ru7r5c/Ifb38Gbmv/8G9tR59rbmih3mzNQmV8anX9NFaEr3DCNVx73PprVd0796GZaiSI3AISWJY5N5SHkOAFOndt9Y6zhajOkjTCmy6+XOYU4zzvn7U70mei7aQJjf9DRirhrscaVgke7RLqYox5FcVZ4UqQyEVqXkPOuTbn9NcAQm0nBDdTTp3vZZxaVOBYE0wKKlRQ14R18Pc5aGdalDLOV3Hk6lCVBWm4dS2P3VcZ1J3TGE2UybOlLJCvlCEP3pSe+QxAanhDEZJQpxSYzRn3wVSxZupOpGJR2oONct1Ph7mjjMzMxOE0TmzQIv4TolKBI3aGrwgNVyqcMb4aMwpZedan8e5ZjGDefGpEbX2jCrjdAT14AxJ5SjvJxd9x3lPfWOwx3BHz/f6PMopSHXI2uNJX6Qu5RXx7IUvgVSJnthcxhcXIm3STMqrEdO6X/rzNHfupEoQLik/+k+rXP07zI2S2J2h5FAio5LLjcVgRFTIJI4HarWKe/YnHpEixT2TVcaVFyR656Kg6YBAeraKM59VEV9gYFEnXsU9T2QapNOleTbGRd9pLoVSxUiSK1M/U/OSbmVcqZFFLlFGipgS+lLHJzVhjjlplPxpmZ4ivvfs9GSv0CrX+XmYS5UOZEPnzAR5jqsyPjNFnEydo1FZ4pMWKeKOvJkdijkqjsY5Ucq5nwUQoxa/V0r5nDkcWwrUhOCaUsbZQpzBIJGY1bCniDv1SYY4ljZ0dlbx39fLn5H7evkzcl8vf0bu69/zT4OmbPSQLGeOAAAAAElFTkSuQmCC",
      },

      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
    },
    {
      pageId: "login-verify-email.ftl",
      // @ts-expect-error: error
      login: {
        username: "test@test.io",
      },

      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
    },
    {
      pageId: "login-reset-password.ftl",
      // @ts-expect-error: error
      login: {
        username: "test@test.io",
      },

      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
    },
    {
      pageId: "login.ftl",
      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
      //Uncomment the following line for hiding the Alert message
      //"message": undefined
      //Uncomment the following line for showing an Error message
      //message: { type: "error", summary: "This is an error" }
    },
    {
      pageId: "my-extra-page-2.ftl",
      someCustomValue: "foo bar baz",
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: "register-user-profile.ftl",
      locale: {
        currentLanguageTag: "fr",
      },
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: "^[a-zA-Z0-9]+$",
                "ignore.empty.value": true,
                // eslint-disable-next-line no-template-curly-in-string
                "error-message": "${alphanumericalCharsOnly}",
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: "username",
          },
          {
            validators: {
              options: {
                options: ["male", "female", "non_binary", "prefer_not_to_say"],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: "${gender}",
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: "gender",
          },
        ],
      },
    },
    {
      pageId: "register.ftl",
      authorizedMailDomains: [
        "example.com",
        "another-example.com",
        "*.yet-another-example.com",
        "*.example.com",
        "hello-world.com",
      ],
      // Simulate we got an error with the email field. Return text if message for given field exists.
      messagesPerField: {
        printIfExists: <T>(fieldName: string, text: T) => {
          // console.log({ fieldName });
          return fieldName === "email" ? text : undefined;
        },
        existsError: (fieldName: string) => fieldName === "email",
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === "email",
      },
    },
  ],
  // Defined in vite.config.ts
  // See: https://docs.keycloakify.dev/environnement-variables
  mockProperties: {
    MY_ENV_VARIABLE: "Mocked value",
  },
});

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  //mockPageId: "login.ftl",
});

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
